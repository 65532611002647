import Layout from "../layouts";
import { Link } from "gatsby";
import React from "react";

const IndexPage = () => (
  <Layout>
    <p className="leads-the-things">
      Hey!{" "}
      <span role="img" aria-label="wave emoji">
        👋
      </span>{" "}
      Thanks for stopping by!
    </p>
    <p>
      We write software to help people because it's rewarding and we love doing
      it, which we think is a good reason to do just about anything.
    </p>
    <p>
      If this sounds like you too, get in touch, we'd{" "}
      <Link to="/contact">love to chat</Link>.
    </p>
    <p>
      Otherwise, here you can learn more about{" "}
      <Link to="/about">who we are and what we do</Link>, check out our{" "}
      <Link to="/work">portfolio</Link>, or take a look at our{" "}
      <Link to="/blogs">blogs</Link>.
    </p>
  </Layout>
);

export default IndexPage;
